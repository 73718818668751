import React, { useEffect, useState } from "react";
import { CustomBackground } from "../../components/background/CustomBackground";
import backgrooundImg from "../../assets/images/register-bg.jpg";
import Container from "../../components/container/Container";
import { Link, useNavigate } from "react-router-dom";
import { LoginCard, PlanSelectcard } from "../../components/cards/card";
import PrimaryButton from "../../components/buttons/button";
import { Topbar } from "../../layout/topbar";
import { getPrice, getTimezone, showFormErrors } from "../../utils/commonFunctions";
import formValidation from "../../utils/validation";
import Signup from "../../pages/signup";
import LogoImg from "../../assets/images/logo.png";
import { CustomOtpInput } from "../../components/input/allinput";
import { ResendOTP } from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterAction } from "../../store/thunks/userThunk";
import { isAuthenticated } from "../../services/auth";
import { getUserUnauthorizedPlans } from "../../store/thunks/planThunk";
import PLanIsllustration from "../../assets/images/PlanIllustration.jpg";
import constants from "../../constants";

export default function Plan() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [data, setData] = useState({
        selectPlan: "",
        firstName: "",
        email: "",
        password: "",
        confirmPassword: "",
        isChecked: false,
        otp: "",
    });
    useEffect(() => {
        if (isAuthenticated()) {
            navigate("/membership");
        }
    }, [navigate]);
    useEffect(() => {
        dispatch(getUserUnauthorizedPlans());
    }, [dispatch]);

    const { plans } = useSelector((state) => state.plan);
    const planFeatures = plans?.length && plans?.find((item) => item._id === data.selectPlan);
    const [selectPlan, setSelectPlan] = useState(true);
    const [signup, setSignUp] = useState(false);
    const [verifyOtp, setVerifyOtp] = useState(false);

    const handleSelect = () => {
        let ignore = [];
        if (selectPlan) {
            ignore = ["firstName", "email", "password", "confirmPassword", "otp"];
        } else if (signup) {
            ignore = ["otp"];
        } else {
            ignore = [];
        }
        if (showFormErrors(data, setData, ignore)) {
            setSignUp(true);
        }
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const renderButton = (buttonProps) => {
        return (
            <p className="bottom-link text-center heading-color font-normal col-12 ">
                Didn't Receive Code?
                <button className="  border-none bg-transparent  font-bold" {...buttonProps}>
                    {"Resend"}
                </button>
            </p>
        );
    };

    // Handle Razorpay payment for the subscription
    const handleRazorpaySubscription = (subscriptionId) => {
        const options = {
            key: constants.razorPayKeyId, // Add your Razorpay key_id
            subscription_id: subscriptionId,
            name: "Nama: Meditation",
            description: "Subscription",
            handler: function (response) {
                // Redirect to your success page with response details
                // window.location.href = `/success?paymentId=${response.razorpay_payment_id}&subscriptionId=${subscriptionId}`;
            },
            // prefill: {
            //     name: "Customer Name",
            //     email: "customer@example.com",
            //     contact: "9999999999",
            // },
            theme: {
                color: "#3399cc",
            },
            modal: {
                ondismiss: function () {},
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    const handleVerify = () => {
        if (showFormErrors(data, setData)) {
            dispatch(userRegisterAction(data, data.selectPlan ? () => navigate("/membership") : handleRazorpaySubscription));
        }
    };

    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 md:col-7 lg:col-7 flex flex-column w-full mx-0 px-0">
                <p className="ml-3 flex font-medium justify-content-end align-items-end m-0 ">00:{remainingTime} </p>
                <PrimaryButton btnclass="mt-3 bg-main w-full" label={"Verify"} onClick={handleVerify} />
            </div>
        );
    };
    return (
        <>
            {signup ? (
                <Signup
                    setSelectPlan={setSelectPlan}
                    setVerifyOtp={setVerifyOtp}
                    setSignUp={setSignUp}
                    handleChange={handleChange}
                    data={data}
                    setData={setData}
                />
            ) : verifyOtp ? (
                <>
                    <Topbar />
                    <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                        <LoginCard>
                            <Link to="https://www.namameditation.com/">
                                <img src={LogoImg} alt="" style={{ width: "140px" }} />
                            </Link>
                            <h1 className="md:text-center font-semibold heading-size text-main">OTP Verification</h1>
                            <p className="subheading-color text-xl nunito">Enter the verification code we’ve just sent on your email address.</p>
                            <CustomOtpInput data={data} onChange={handleChange} name="otp" />
                            <div className="text-right mt-2">
                                <ResendOTP
                                    onResendClick={console.log("resennd")}
                                    renderButton={renderButton}
                                    renderTime={renderTime}
                                    className="flex-column mt-4"
                                />
                            </div>
                        </LoginCard>
                    </CustomBackground>
                </>
            ) : selectPlan ? (
                <>
                    <Topbar />
                    <CustomBackground className="py-5 flex flex-column align-items-center justify-content-between px-0 md:p-5" bgImg={backgrooundImg}>
                        <Container containerClass="align-items-center justify-content-between h-full">
                            <div className="bg-white w-full border-round-lg h-full p-5 md:p-6">
                                <h1 className="md:text-center font-semibold sub-heading-size text-main ">Choose Your Plan</h1>
                                <div className="grid w-full justify-content-between m-0">
                                    <div className="col-12 md:col-6 md:border-right-1 border-300 p-0">
                                        <div className="md:pr-5">
                                            <p className="text-lg md:text-xl heading-color line-height-3 mt-0 mb-3 md:mb-5 nunito">{planFeatures?.tagLine}</p>

                                            {data?.selectPlan ? (
                                                <ul className="list-none p-0">
                                                    {planFeatures?.features?.map((item) => (
                                                        <li className="font-medium mb-3 md:mb-5 flex gap-3 text-sm md:text-base nunitoe" key={item._id}>
                                                            <i className="pi pi-check text-main"></i>
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <img
                                                    src={PLanIsllustration}
                                                    alt=""
                                                    className="w-full"
                                                    style={{ height: "408px", objectFit: "cover", objectPosition: "bottom" }}
                                                />
                                            )}
                                            <p className="text-sm md:text-lg line-height-3 nunito hidden md:block">
                                                By purchasing a subscription you accept the{" "}
                                                <Link to="https://www.namameditation.com/eula" target="_blank" className="text-main no-underline">
                                                    Terms of Use (EULA)
                                                </Link>{" "}
                                                and{" "}
                                                <Link to="https://www.namameditation.com/privacypolicy" target="_blank" className="text-main no-underline">
                                                    Privacy Policy
                                                </Link>{" "}
                                                of the app.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-5 p-0">
                                        <div className="md:pl-5">
                                            {plans?.length > 0 &&
                                                plans?.map((item) => (
                                                    <PlanSelectcard
                                                        title={item.name}
                                                        key={item._id}
                                                        plan={getPrice(item)}
                                                        selectedBox={data.selectPlan === item._id ? true : false}
                                                        value={item._id}
                                                        handleChange={handleChange}
                                                        data={data}
                                                    />
                                                ))}

                                            <p className="text-sm md:text-lg line-height-3 nunito block md:hidden">
                                                By purchasing a subscription you accept the{" "}
                                                <Link to="https://www.namameditation.com/eula" target="_blank" className="text-main no-underline">
                                                    Terms of Use (EULA)
                                                </Link>{" "}
                                                and{" "}
                                                <Link to="https://www.namameditation.com/privacypolicy" target="_blank" className="text-main no-underline">
                                                    Privacy Policy
                                                </Link>{" "}
                                                of the app.
                                            </p>

                                            <div className="p-error open-sans text-sm mb-2"> {data?.formErrors?.selectPlan}</div>
                                            <PrimaryButton label="Select" btnclass="bg-main w-full mb-3" onClick={handleSelect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </CustomBackground>
                </>
            ) : null}
        </>
    );
}
