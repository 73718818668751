import React, { useState, useEffect } from "react";
import DashboardLayout from "../../dashboardlayout";
import { PlanSelectcard } from "../../components/cards/card";
import PrimaryButton from "../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { CancelPaymentAction, getUserPaymentLink, getUserPlans } from "../../store/thunks/planThunk";
import formValidation from "../../utils/validation";
import { getPrice, getTimezone, showFormErrors } from "../../utils/commonFunctions";
import PlanIllustration from "../../assets/images/PlanIllustration.jpg";
import { Link } from "react-router-dom";
import ActivePlans from "./ActivePlans";
import UpcomingPlans from "./UpcomingPlans";
import CancelDialog from "./CancelDialog";
import UpgradeDialog from "./UpgradeDialog";
import { socket } from "../../socket";
import { hideLoader } from "../../store/slices/loaderSlice";
import constants from "../../constants";

export default function Membership() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        selectPlan: "",
    });

    const [cancelPlan, setCancelPlan] = useState(null);
    const [upgradePlanOpen, setUpgradePlanModelOpen] = useState(false);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    useEffect(() => {
        socket.on("update-plans", () => {
            dispatch(getUserPlans());
            setUpgradePlanModelOpen(false);
            // setCancelPlan(false);
        });
    }, [socket.connected]);

    useEffect(() => {
        dispatch(getUserPlans());
    }, [dispatch]);

    // useEffect(() => {
    //     // Access the query string using `window.location.search`
    //     const searchParams = new URLSearchParams(window.location.search);
    //     // Retrieve specific query parameters
    //     const isLoading = JSON.parse(searchParams.get("showLoader"));
    //     if (isLoading) {
    //         setHideLoading(false);
    //     } else {
    //         setHideLoading(true);
    //     }
    // }, []);

    const { plans } = useSelector((state) => state.plan);

    const planFeatures = plans?.plans?.length && plans?.plans?.find((item) => item._id === data.selectPlan);

    // Handle Razorpay payment for the subscription
    const handleRazorpaySubscription = (subscriptionId) => {
        const options = {
            key: constants.razorPayKeyId, // Add your Razorpay key_id
            subscription_id: subscriptionId,
            name: "Nama: Meditation",
            description: "Subscription",
            handler: function (response) {
                // Redirect to your success page with response details
                // window.location.href = `/success?paymentId=${response.razorpay_payment_id}&subscriptionId=${subscriptionId}`;
            },
            // prefill: {
            //     name: "Customer Name",
            //     email: "customer@example.com",
            //     contact: "9999999999",
            // },
            theme: {
                color: "#3399cc",
            },
            modal: {
                ondismiss: function () {
                    dispatch(getUserPlans());
                    // Handle case when the user closes the modal
                    setUpgradePlanModelOpen(false);
                },
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    const handlePaymentLink = () => {
        if (showFormErrors(data, setData)) {
            if (activePlan?.length > 0) {
                setUpgradePlanModelOpen(true);
            } else {
                dispatch(getUserPaymentLink({ id: data.selectPlan, handleRazorpaySubscription }));
            }
        }
    };

    const handleCancelPlan = () => {
        dispatch(
            CancelPaymentAction(cancelPlan, () => {
                setTimeout(() => {
                    setCancelPlan(false);
                    dispatch(getUserPlans());
                    dispatch(hideLoader());
                }, 2000);
            })
        );
    };

    const handleUpgradePlan = () => {
        dispatch(getUserPaymentLink({ id: data.selectPlan, handleRazorpaySubscription }));
    };

    const activePlan = plans?.activePlan;
    // const upcomingPlan = plans?.activePlan?.filter((item) => new Date(item.startOn) > new Date());

    return (
        <DashboardLayout heading="Membership Plans">
            <div className="w-full ">
                {activePlan && Object.keys(activePlan).length ? <ActivePlans plans={plans} activePlan={activePlan} setCancelPlan={setCancelPlan} /> : null}
                {/* <UpcomingPlans plans={plans} upcomingPlan={upcomingPlan} /> */}
                <CancelDialog cancelPlan={cancelPlan} setCancelPlan={setCancelPlan} handleCancelPlan={handleCancelPlan} />
                <UpgradeDialog
                    upgradePlanOpen={upgradePlanOpen}
                    setUpgradePlanModelOpen={setUpgradePlanModelOpen}
                    activePlan={activePlan}
                    handleUpgradePlan={handleUpgradePlan}
                    // upcomingPlan={upcomingPlan}
                />

                {plans?.plans?.length > 0 && Object.keys(plans?.activePlan)?.length === 0 && (
                    <h1 className="md:text-center font-semibold sub-heading-size text-main ">Choose Your Plan</h1>
                )}

                <div className="grid w-full m-0 justify-content-between">
                    <div className="col-12 md:col-6 md:border-right-1 border-300 p-0">
                        {plans?.plans?.length > 0 && plans?.activePlan?.length > 0 ? (
                            <div className="md:pr-5">
                                <p className="text-lg md:text-lg font-semibold heading-color mt-0 mb-3 md:mb-5">Other Plans</p>
                                {data?.selectPlan ? (
                                    <ul className="list-none p-0">
                                        {planFeatures?.features?.map((item) => (
                                            <li className="font-medium mb-3 md:mb-5 flex gap-3 text-sm md:text-base nunitoe" key={item._id}>
                                                <i className="pi pi-check text-main"></i>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="flex justify-content-center align-items-center">
                                        <img src={PlanIllustration} alt="" className="w-8" />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="md:pr-5 w-full md:w-10">
                                    <p className="text-lg md:text-xl heading-color line-height-3 mt-0 mb-3 md:mb-5 nunito font-medium">
                                        {planFeatures?.tagLine}
                                    </p>
                                    {data?.selectPlan ? (
                                        <ul className="list-none p-0">
                                            {planFeatures?.features?.map((item) => (
                                                <li className="font-medium mb-3 md:mb-5 flex gap-3 text-sm md:text-base nunitoe" key={item._id}>
                                                    <i className="pi pi-check text-main"></i>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="mb-2 flex justify-content-center">
                                            <img
                                                src={PlanIllustration}
                                                alt=""
                                                className=""
                                                style={{ width: "400px", height: "350px", objectFit: "cover", objectPosition: "bottom" }}
                                            />
                                        </div>
                                    )}
                                    <p className="text-sm md:text-lg line-height-3 nunito hidden md:block">
                                        By purchasing a subscription you accept the{" "}
                                        <Link to="https://www.namameditation.com/eula" target="_blank" className="text-main no-underline">
                                            Terms of Use (EULA)
                                        </Link>{" "}
                                        and{" "}
                                        <Link to="https://www.namameditation.com/privacypolicy" target="_blank" className="text-main no-underline">
                                            Privacy Policy
                                        </Link>{" "}
                                        of the app.
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-12 md:col-5 p-0">
                        <div className="md:pl-5">
                            {plans?.plans?.length > 0 &&
                                plans?.plans?.map((item) => {
                                    return (
                                        <PlanSelectcard
                                            title={item.name}
                                            key={item._id}
                                            plan={getPrice(item)}
                                            selectedBox={data.selectPlan === item._id ? true : false}
                                            // onClick={() => handleCard(item._id)}
                                            value={item._id}
                                            handleChange={handleChange}
                                            data={data}
                                        />
                                    );
                                })}
                            <div className="p-error open-sans text-sm mb-2"> {data?.formErrors?.selectPlan}</div>

                            {plans?.plans?.length > 0 && (
                                <>
                                    <PrimaryButton
                                        label={activePlan?.length > 0 ? "Upgrade" : "Select"}
                                        disable={activePlan?.length && activePlan[0]?.status === "created"}
                                        btnclass="bg-main w-full mb-3"
                                        onClick={handlePaymentLink}
                                    />
                                </>
                            )}
                            <p className="text-sm md:text-lg line-height-3 nunito block md:hidden">
                                By purchasing a subscription you accept the{" "}
                                <Link to="https://www.namameditation.com/eula" target="_blank" className="text-main no-underline">
                                    Terms of Use (EULA)
                                </Link>{" "}
                                and{" "}
                                <Link to="https://www.namameditation.com/privacypolicy" target="_blank" className="text-main no-underline">
                                    Privacy Policy
                                </Link>{" "}
                                of the app.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
