const endPoints = {
    REQUEST_OTP: "/request-otp",
    REGISTER: "/signup",
    LOGIN: "/login",
    PLANS: "/plan",
    PAYMENT_LINK: "/payment-link/",
    RAZORPAY_PAYMENT_LINK: "/razorpay-payment-link/",
    PROFILE: "/my-profile",
    UNPLANS: "/plans",
    SENT_OTP: "/forget-password",
    RESET_FORGOT_PASSWORD: "/change-forget-password",
    SOCIAL_LOGIN: "/social-signin",
    CANCEL_PAYMENT: "/cancel-subscription",
    CANCEL_RAZORPAY_PAYMENT: "/razorpay-cancel-subscription",
};
export default endPoints;
